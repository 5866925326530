/** @format */

html {
	height: -webkit-fill-available; /* this is here as part of a solution for mobile view prevention  of 'sliding' */
}
body {
	color: white;
	background-color: black;
	padding: 0;
	margin: 0;
	height: 100vh;
	height: -webkit-fill-available; /* this is here as part of a solution for mobile view prevention  of 'sliding' */
	display: flex;
	justify-content: center;
	align-items: center;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

#root {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Main Container wit initial elements setup */
.ch-a-container {
	/* border: 3px solid wheat; */
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	overflow: hidden;
	position: relative;
}
.ch-side-left {
	/* border: 3px solid purple; */
	min-width: 300px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}
/* this is a placeholder for the searchbar, making the space in the flow where the searchbar shoud be */
.ch-search {
	/* border: 13px solid red; */
	width: 100%;
	min-height: 75px;
	box-sizing: border-box;
	display: flex;
	/* justify-content: center;
	align-items: center; */
}
/* searchbar is fixed and goes over the search placeholder */
.ch-searchbar {
	position: fixed;
	top: 100px;
}
.ch-menu-wrapp {
	overflow-y: auto;
}
.ch-menu {
	/* border: 2px solid green; */
	width: 100%;
	padding: 10px 5px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.ch-menu-splitter {
	width: 100%;
	display: flex;
	align-items: center;
	/* padding-left: 16px; */
	color: #69707e;
	font-weight: bold;
	font-size: 13px;
	white-space: pre;
}
.ch-menu-splitter hr {
	margin-left: 10px;
	width: 100%;
	/* border-top: 1px solid #69707E; */
	border-right-width: 0px;
	border-bottom-width: 0px;
	border-left-width: 0px;
	border-color: #a0a1a3;
}
.ch-list {
	width: -webkit-fill-available;
}
/* .ch-menu-item {
  font-weight: bold!important;
} */
.MuiListItemText-primary {
	font-weight: bold !important;
}
.ch-menu-item:hover {
	/* background-color: rgba(255, 255, 255, 0.08); */
	background-color: #242a38;
	border-radius: 2px;
}
.animate {
	margin-left: 0px !important;
}
.animate-sideDetails {
	margin-right: 0px !important;
}
.ch-content-right {
	z-index: 5;
	background: white;
	/* border: 3px solid grey; */
	width: -webkit-fill-available;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	box-sizing: border-box;
}
.ch-header {
	color: black;
	/* border: 3px solid blue; */
	border-bottom: 1px solid #e0e0e0;
	height: 75px;
	position: absolute;
	top: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}
.ch-table-wrapper {
	width: 100%;
	overflow: auto;
	/* border: 2px solid red; */
	box-sizing: border-box;
}
.MuiTableContainer-root {
	border: #242a38;
}

/* SIDE DETAILS */
.sd-details-wrapper {
	/* border: 3px solid red; */
	min-width: 500px;
	height: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	overflow: auto;
	background: white;
	color: rgba(0, 0, 0, 0.87);
	margin-right: -500px;
	transition: margin-right 0.25s;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
	z-index: 6;
}
.sd-height {
	min-height: 262px;
}
.sd-common {
	/* border: 3px solid red; */
	width: 100%;
	font-size: 14px;
	display: flex;
	justify-content: center;
	box-sizing: border-box;
}
.sd-header {
	/* height: 75px; */
	background: orange;
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 16px;
	border-bottom: none;
}
.sd-details {
	/* flex-grow: 1; */
	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.sd-details h3,
.sd-products h3 {
	align-self: self-start;
	margin-bottom: 16px;
}
.sd-details-row-wrapper {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
}
.sd-details-row-wrapper > div {
	/* border: 1px solid red; */
	width: 50%;
	display: grid;
	align-items: center;
	height: 40px;
}
.sd-details-row-left {
	text-align: end;
	padding-right: 10px;
}
.sd-details-row-right {
	text-align: start;
	padding-left: 10px;
}
.sd-separator {
	/* height: 30px; */
	width: 90%;
	border-top: 1px solid #dbdbdb;
	margin: 10px 0;
}
.sd-products {
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

/* PDF Excel buttons */
.pdf-btn {
	background-color: red !important;
	margin-right: 15px !important;
}
.xls-btn {
	background-color: green !important;
}

.pdf-exl-wrap {
	width: -webkit-fill-available;
	display: flex;
	align-items: center;
	padding: 0 15px;
}
.pdf-exl-wrap h3 {
	white-space: nowrap;
}
.pdf-exl-buttons-wrap {
	width: -webkit-fill-available;
	display: flex;
	justify-content: flex-end; /* or space-evenly without margin-right on the buttons */
}
a:-webkit-any-link {
	text-decoration: none !important;
}

/* SCROLLBAR STYLES */
.scroll-handler::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}
.scroll-handler::-webkit-scrollbar-thumb {
	background: orange;
}
/* .scroll-handler::-webkit-scrollbar-track {
	background-color: rgb(255, 255, 255);
} */
.scroll-handler::-webkit-scrollbar-thumb:hover {
	background: rgb(255, 135, 0);
}
.scroll-handler::-webkit-scrollbar-thumb:horizontal:hover {
	background: rgb(255, 135, 0);
}

.clicked-row {
	background-color: antiquewhite !important; /* Customize the background color or any other styling */
}

/* Router links */
.router-link {
	text-decoration: none;
	display: flex;
	color: unset;
	width: 100%;
}

.selected-router-link {
	background-color: #f1801e !important;
	border-radius: 2px;
}

.ch-menu-toggle {
	display: none !important;
}

@media (max-width: 801px) {
	.ch-side-left.added {
		display: none;
	}
	.ch-menu-toggle {
		display: inline-flex !important;
	}
}
@media (max-width: 500px) {
	.sd-details-wrapper {
		min-width: 100%;
	}
}
